import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsRoutingModule } from './components-routing.module';
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ProjectsDatatablesComponent } from './projects-datatables/projects-datatables.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { DocUploaderComponent } from './doc-uploader/doc-uploader.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxFileDropModule } from 'ngx-file-drop';
import { StepperIconComponent } from './stepper-icon/stepper-icon.component';
import { ListDocumentsComponent } from './list-documents/list-documents.component';
import { GeneralInfoYourProjectsComponent } from './general-info-your-projects/general-info-your-projects.component';
import { FullCalendarComponent } from './full-calendar/full-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { GeneralFormComponent } from './general-form/general-form.component';
import { MatStepperModule } from '@angular/material/stepper';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { DetailProjectComponent } from './detail-project/detail-project.component';
import { DynamicDatatablesComponent } from './dynamic-datatables/dynamic-datatables.component';
import { MatSelectModule } from '@angular/material/select';
import {MatChipsModule} from '@angular/material/chips';
import { ArchiveFilesComponent } from './archive-files/archive-files.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ProjectsDatatablesComponent,
    PaginatorComponent,
    BreadcrumbsComponent,
    DocUploaderComponent,
    StepperIconComponent,
    ListDocumentsComponent,
    GeneralInfoYourProjectsComponent,
    FullCalendarComponent,
    GeneralFormComponent,
    DetailProjectComponent,
    DynamicDatatablesComponent,
    ArchiveFilesComponent,
  ],
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    TranslateModule,
    HttpClientModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgxFileDropModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    FormsModule,
    SweetAlert2Module,
    MatTooltipModule,
    FullCalendarModule,
    MatStepperModule,
		CurrencyMaskModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatChipsModule,
		MatAutocompleteModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ProjectsDatatablesComponent,
    PaginatorComponent,
    BreadcrumbsComponent,
    DocUploaderComponent,
    StepperIconComponent,
    ListDocumentsComponent,
    GeneralInfoYourProjectsComponent,
    FullCalendarComponent,
    GeneralFormComponent,
    DynamicDatatablesComponent
  ]
})
export class ComponentsModule { }
