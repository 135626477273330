import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { DocumentDTO } from '../models/document-type';
import { environment } from 'src/environments/environment';
import { FormattedResponse } from "../models/formatted-response";
import { HttpContextConfig } from '../models/http-context-config';
import { CONFIG } from '../helpers/interceptor.service';

@Injectable({
  providedIn: 'root'
})

export class ProjectsService {
  constructor(private http: HttpClient) { }

  /**
  * Used to create a new project
  * @param params
  * @returns
  */
  createDocumentProject(params: DocumentDTO): Observable<any> {
    return this.http.post(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects`, params);
  }

  /**
  * Used to get the project
  * @param search
  * @param pageNumber
  * @param pageSize
  * @param filter
  * @returns
  */
  getYourProjects(search: any, pageNumber: number, pageSize: number, filter: string, order: any): Observable<any> {
    if (order == null || order == '' || order == undefined) order = "{\"column\":\"Created\",\"value\":\"DESC\"}"
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects?pageSize=${pageSize}&pageNumber=${pageNumber}&order=${order}&filter=${filter}&search=${search}`);
  }

  exportExcel(filterValue: string, searchValue: string) {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/export?filter=${searchValue}&search=${filterValue}`, { responseType: 'blob' });
  }

  /**
  * Used to get the details your projects
  * @param project_id
  * @returns
  */
  getProject(project_id: string): Observable<any> {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${project_id}`);
  }

  /**
  * Used to get the details your projects
  * @param project_id
  * @returns
  */
  getProjectDocument(project_id: string): Observable<any> {
    return this.http.get<FormattedResponse<any>>(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${project_id}/documents`);
  }

  /**
  * Change status a document
  * @param project_id
  * @param document_id
  * @param data
  * @returns
  */
  changeStatusDocument(project_id: string, document_id: string, data: any): Observable<any> {
    return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/approve/${project_id}/${document_id}`, data);
  }

  /**
  * Used to get the details your projects
  * @param projectId
  * @param project
  * @returns
  */
    updateProjectInfo(projectId: string, project : any): Observable<any> {
      return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${projectId}/info`,project);
    }

  /**
  * Used for inser note about the documents rejected
  * @param projectId
  * @param documentId
  * @param note
  * @returns
  */
  noteRejectDocuments(projectId: any, document_id: string, note: any): Observable<any> {
    let config = new HttpContextConfig();
    config.noSpinner = true;

    const context = new HttpContext().set(CONFIG, config);
    return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${projectId}/${document_id}/note`, { note }, { context: context });
  }

  /**
  * Used for inser note about the documents rejected
  * @param projectId
  * @returns
  */
  submitDocuments(projectId: any): Observable<any> {
    return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/submit/${projectId}/directory`, null);
  }

  /**
  * Used for re-upload the documents rejected
  * @param projectId
  * @param file
  * @returns
  */
  reUploadDocuments(projectId: string, file: any): Observable<any> {
    return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${projectId}/documents`, file);
  }

  /**
  * Used for re-upload the documents rejected
  * @param projectId
  * @param data
  * @returns
  */
  uploadFileIntegrations(projectId: string, data: any) {
    return this.http.post(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${projectId}/document`, data);
  }

  /**
  * Used for get information status for timeline 
  * @param projectId
  * @returns
  */
  getTimelineProject(project_id: string) {
    // return this.http.get('assets/data/comma.json');
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${project_id}/timeline`);
  }

  /**
  * Used for send all directory
  * @param projectId
  * @returns
  */
  directorySubmit(project_id: string) {
    return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${project_id}/directory/submit`, null)
  }

  /**
  * Used for get project upload
  * @param projectId
  * @returns
  */
  getProjectUploadDocument(project_id: string) {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${project_id}/upload/documents`)
  }


  /**
  * Used for get check documents
  * @param projectId
  * @param documentCode
  * @returns
  */
  getCheckDocuments(project_id: string, documentCode: string) {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${project_id}/documents/check?document_code=${documentCode}`)
  }

  /**
  * Used for checkbox public discussion
  * @param data
  * @param project_id
  * @returns
  */
  changePublicDiscussion(data: any, project_id: string) {
    return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${project_id}`, { PublicDiscussion: data })
  }

  /**
  * Used for request integration
  * @param data
  * @param project_id
  * @returns
  */
  requestIntegration(data: any, project_id: string) {
    return this.http.put(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${project_id}/request/integration`, data)
  }

  getStations(page_number: any, page_size: any, order: any, search: any): Observable<any> {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_user}/stationsaccount?pageNumber=${page_number}&pageSize=${page_size}&search=${search}&order=${order}`);
  }


  createGroup(data: any, projectId: string) {
    return this.http.post(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${projectId}/appointment`, data);
  }

  checkDocuments(projectId: string, code: string) {
    return this.http.get(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/projects/${projectId}/documents/check/${code}`);
  }

  documentCreate(projectId: string, data: any) {
    return this.http.post(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/files/${projectId}/document/create`, data);
  }

  documentCode(projectId: string, data: any) {
    return this.http.post(`${environment.wso2_apim.url}/${environment.wso2_apim.ms_project}/files/${projectId}/document`, data);
  }

}
