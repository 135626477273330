import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import {BehaviorSubject, debounceTime, distinctUntilChanged} from "rxjs";
import {Element} from "@angular/compiler";
import {di} from "@fullcalendar/core/internal-common";


@Component({
	selector: 'app-general-form',
	templateUrl: './general-form.component.html',
	styleUrls: ['./general-form.component.scss']
})
export class GeneralFormComponent implements OnInit {
	@Output() multiSelect: EventEmitter<any> = new EventEmitter<any>();
	@Output() patchImage: EventEmitter<any> = new EventEmitter<any>();

	@Input() itemSelect: any;
	@Input() dynamicForms: any;

	autocompleteControl = new FormControl();

	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	addOnBlur = true;
	texts: any[] = [];

	isEmailInvalid: boolean = false;

	formGroup: FormGroup = new FormGroup({});
	loadedForm$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	currencyMaskConfig = {
		align: "left",
		allowNegative: false,
		allowZero: true,
		decimal: ",",
		precision: 2,
		prefix: "",
		suffix: " €",
		thousands: ".",
	}

	constructor() {
		this.autocompleteControl.valueChanges.pipe(
			distinctUntilChanged(),
			debounceTime(500)
		).subscribe((value) => {
			let index = this.dynamicForms.findIndex((el: any) => el.type === 'autocomplete');
			if (index !== -1) {
				this.dynamicForms[index].autocompleteChange(value);
				this.formGroup.get(this.dynamicForms[index].formControlName)!.markAsTouched();
			}
			console.log(value);
			if (value == ''){
				this.formGroup.get(this.dynamicForms[index].formControlName)!.updateValueAndValidity();
			}
		});
	}

	setTouchedOnAutocomplete() {
		let index = this.dynamicForms.findIndex((el: any) => el.type === 'autocomplete');
		if (index !== -1) {
			this.formGroup.get(this.dynamicForms[index].formControlName)!.markAsTouched();
		}
	}

	ngOnInit(): void {
		this.createForm();
		this.loadedForm$.next(true);
	}
	createForm() {
		this.dynamicForms.forEach((element: any) => {
			this.formGroup.addControl(element.formControlName, new FormControl('', element.validators));
		});
	}

	onSelectionChange(event: any) {
		if (event.value) {
			this.multiSelect.emit(event.value)
		}
	}

	add(event: MatChipInputEvent): void {
		const inputValue = (event.value || '').trim();

		if (!inputValue) {
			return;
		}

		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

		if (emailRegex.test(inputValue)) {
			this.isEmailInvalid = false;
			this.texts.push(inputValue);
			this.updateChiplistValues();
		} else {
			this.isEmailInvalid = true;
		}

		event.chipInput!.clear();
	}

	remove(fruit: any): void {
		const index = this.texts.indexOf(fruit);

		if (index >= 0) {
			this.texts.splice(index, 1);
			this.updateChiplistValues();
		}
	}

	resetChiplist(): void {
		this.texts = [];
		this.updateChiplistValues();
	}

	pushChipList(event: any) {
		this.texts = event.slice();
		this.updateChiplistValues();
	}

	updateChiplistValues(): void {
		this.dynamicForms.forEach((element: any) => {
			if (element.type === 'chiplist') {
				this.formGroup.patchValue({ [element.formControlName]: this.texts });
			}
		});
	}

	onInputChange(event: Event) {
		const input = event.target as HTMLInputElement;
		input.value = input.value.toUpperCase();
		this.form.get('fiscal_code')?.setValue(input.value, { emitEvent: false });
	  }

	onOptionSelected(event: any, formControlName: string) {
		const selectedValue = event.option.value;
		// Imposta il valore del form principale
		this.formGroup.get(formControlName)!.setValue(selectedValue);
		this.autocompleteControl.setValue(selectedValue);
	}

	get form() {
		return this.formGroup;
	}

	get formValue() {
		return this.formGroup.value;
	}

	get formValid() {
		return this.formGroup.valid;
	}

	get formInvalid() {
		return this.formGroup.invalid;
	}

}
