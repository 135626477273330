import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-stepper-icon',
  templateUrl: './stepper-icon.component.html',
  styleUrls: ['./stepper-icon.component.scss']
})
export class StepperIconComponent implements OnInit {
  /**
  * Contains all the information about the your project
  * @type {BehaviorSubject<any[]>}
  * @Input
  */

  /**
   * obtains al the steps of BE
   */
  public steps = {
    State: [
      {
        StepPlaceholder: "In istruttoria",
        Start: "27/20/2022",

      },
      {
        StepPlaceholder: "In istruttoria",
        Start: "27/20/2022",
      },
      {
        StepPlaceholder: "In istruttoria",
        Start: "27/20/2022",
      },
      {
        StepPlaceholder: "In istruttoria",

      },
      {
        StepPlaceholder: "In istruttoria",
      },
    ],
    Step: [
      {
        StepId: 1,
        StepPlaceholder: "Richiesta di parere",
        Daily: "25 gg",
      },
      {
        StepId: 2,
        StepPlaceholder: "Richiesta integrazioni e chiarimenti",
      },
      {
        StepId: 3,
        StepPlaceholder: "Trasmissione integrazioni e chiarimenti",
        Daily: "0 gg",
      },
      {
        StepId: 4,
        StepPlaceholder: "Emanazione parere",
        Daily: "15 gg",
      },
      {
        StepId: 5,
        StepPlaceholder: "Esito conferenza di servizi",
      },

    ],
    StepIndex: 1,
    StateIndex: 1

  }
  @Input() stepYourProjects: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  ngOnInit(): void {
  }


  checkStepperLinee(i: number, stepI: number) {
    if (i < stepI + 1 && i != 0) {
      // colora la riga blu
      return 'col-6 c-stepper__items d-flex flex-row-reverse'
    } else if (this.stepYourProjects.value.State.length + 1 != i && i != 0) {
      // crea la riga grigia
      return 'col-12 c-stepper__items d-flex flex-row-reverse';
    } else {
      return 'col-12 static-class d-flex flex-row-reverse';
    }
  }


  checkStepperNumber(i: number, stepI: number) {
    if (i <= stepI) {
      return 'd-flex circle justify-content-center align-items-center'
    } else {
      return 'd-flex circle disabled justify-content-center align-items-center';
    }
  }

  getRightValue(): string {
    const stepLength = this.stepYourProjects.value.Step?.length;

    if (stepLength === 5) {
      return '6rem';
    } else if (stepLength === 6 || stepLength === 4) {
      return '8rem';
    } else if(stepLength == 3) {
      return '0rem';
    } else if(stepLength == 2) {
      return '-10rem';
    }else {
      return '';
    }
  }

  getMarginLeftValue(): string {
    const stepLength = this.stepYourProjects.value.Step?.length;
    if (stepLength === 3 || stepLength === 4) {
      return '24.8rem';
    } else if (stepLength === 2) {
      return '24.8rem';
    } else if (stepLength === 5) {
      return '18.5rem';
    } else if (stepLength === 6) {
      return '17.3rem';
    } else {
      return ''; // Valore predefinito o nessun margine sinistro applicato
    }
  }


  checkLabel(i: number, step: any) {
    if (step == 6 && step == i + 1) {
      return 'fs-14 fw-700 d-block text-center margin-last-label';
    } else if(step == 5 && step == i + 1) {
      return 'fs-14 fw-700 d-block text-center margin-last-label_5';

    } else if (step == 3 || step == 4) {
      return 'fs-14 fw-700 d-block text-center margin-label_3';
    } else if (step == 2) {
      return 'fs-14 fw-700 d-block text-center margin-label_2';
    } else if (step == 3 && step == i + 1) {
      return 'fs-14 fw-700 d-block text-center margin-last-label_3';
    }
    else {
      return 'fs-14 fw-700 d-block text-center margin-label';
    }
  }

}
