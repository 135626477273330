<table class="table projects-table" aria-describedby="project data table">
  <thead>
    <tr valign="middle">
      <ng-container *ngFor="let column of columnsDatatable">
        <th class="col">
          <div class="col-content">
              <span class="col-text"> {{column.label}} </span>

            <div class="svg-wrapper">
              <svg class="cursor-pointer svg-icon" (click)="changeHref(column.value)" id="{{column.value}}">
                <use [attr.href]="clickedIcon === column.value ? newHref : iconHref"></use>
              </svg>
            </div>
          </div>
        </th>
      </ng-container>
      <th class="col"> {{ "ACTIONS" | translate }} </th>
    </tr>

  </thead>
  <tbody>

    <!-- begin: generate value for row columns  -->
    <tr *ngFor="let row of dataSources" [ngClass]="row.is_disabled ? 'user-disabled' : ''">

      <!-- table users -->
      <ng-container *ngIf="users">
        <th class="data">
          {{ row.username}}
        </th>
        <th class="data">
          {{ row.first_name}} {{row.last_name}}
        </th>
        <th class="data">
          {{ row.email }}
        </th>
        <th class="data">
          {{ getLabel(row.role) }}
        </th>
      </ng-container>


      <!-- table station -->
      <ng-container *ngIf="station">
        <th class="data">
          {{ row.name }}
        </th>
        <th class="data">
          {{ row.code }}
        </th>
        <th class="data">
          {{ row.email }}
        </th>
        <th class="data">
          {{ row.pec }}
        </th>
      </ng-container>
      <!-- end: generate value for row columns  -->

      <!-- begin: button actions "EDIT - DELETE"  -->
      <th>
        <div class="d-flex">
          <div *ngIf="actions.edit" data-bs-toggle="modal" data-bs-target="#uploadModal" (click)="setRow(row)">
            <svg class="icon cursor-pointer">
              <use href="assets/bootstrap-italia/svg/sprites.svg#it-pencil" width="20px"></use>
            </svg>
          </div>
          <ng-container *ngIf="actions.delete && !row.is_disabled">
            <svg class="icon cursor-pointer" (click)="confirmDelete(row, true);">
              <use href="assets/bootstrap-italia/svg/sprites.svg#it-minus-circle" width="20px" style="fill: #CC334D">
              </use>
            </svg>
          </ng-container>
        </div>
      </th>
      <!-- begin: button actions "EDIT - DELETE"  -->
    </tr>

    <ng-container *ngIf="dataSources?.length == 0">
      <tr>
        <td colspan="12" class="text-center">{{"NO_DATA_FOUND" | translate}}</td>
      </tr>
    </ng-container>
  </tbody>
</table>





<!-- begin: modal edit  -->
<div class="modal fade" tabindex="-1" role="dialog" id="uploadModal" aria-labelledby="Drag&Drop Modal"
  data-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title h5 no_toc" id="dragDropModalSection"> {{"EDIT" | translate}}</h3>
        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Chiudi finestra modale">
          <svg class="icon">
            <use href="assets/bootstrap-italia/svg/sprites.svg#it-close"></use>
          </svg>
        </button>
      </div>
      <div class="modal-body text-center">
        <app-general-form [dynamicForms]="dynamicForms" [itemSelect]="itemSelect" #generalFormComponent></app-general-form>
      </div>
      <div class="modal-footer d-flex" [ngClass]="users ? 'justify-content-between' : 'justify-content-end'">
        <button class="d-none" data-bs-dismiss="modal" id="closeModalButton" #closeModalButton></button>
        <ng-container *ngIf="(users && !userIsDisabled); else activeUser">
          <button class="btn btn-danger btn-sm" type="button" (click)="confirmDelete(editForm?.formValue, true);">
            {{"DISATTIVA_UTENTE" | translate}}
          </button>
        </ng-container>
        <ng-template #activeUser>
          <button *ngIf="users" class="btn btn-primary btn-sm" type="button"
            (click)="confirmDelete(editForm?.formValue, false);">
            {{"ATTIVA_UTENTE" | translate}}
          </button>
        </ng-template>
        <button class="btn btn-primary btn-sm" type="button" (click)="confirmSave();">
          {{"SAVE" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- end: modal edit  -->