<div class="row d-flex justify-content-center">
    <div class="wrapper">
        <div class="c-stepper d-flex h-100" [ngStyle]="{ 'right': getRightValue() }">
            <!-- begin: ngFor step your projects -->
            <div class="d-flex">
                <li class="col d-flex row m-auto"
                    *ngFor="let step of (stepYourProjects | async).Step; let i = 'index' ">
                    <!-- begin: div label  -->
                    <div class="mb-4 col-12 h-100">
                        <span [ngClass]="checkLabel(i, (stepYourProjects | async).Step.length)">
                            {{step.StepPlaceholder | translate}} </span>
                    </div>
                    <!-- end: div end label -->

                    <!-- begin: div number with icon box  -->
                    <div [ngClass]="checkStepperLinee(i, (stepYourProjects | async).StepIndex)">
                        <div [ngClass]="checkStepperNumber(i, (stepYourProjects | async).StepIndex)">
                            <h5 class="step-white m-auto"> {{step.StepId}} </h5>
                        </div>
                    </div>
                    <!-- end: end number with icon box  -->


                    <!-- begin: div box with date expiration es: 0gg,15gg  -->

                    <div [ngClass]="(stepYourProjects | async).StateIndex >= i ? 'box-stepper' : 'box-stepper disabled'"
                        [ngStyle]="{ 'margin-left': getMarginLeftValue() }"
                        *ngIf="i+1 != (stepYourProjects | async).Step.length" mat-raised-button
                        matTooltip="{{(stepYourProjects | async).State[i]?.StatePlaceholder | translate}}">
                        <small class="fs-14 fw-700">
                        </small>
                    </div>
                    <!-- end: div box with date expiration es: 0gg,15gg  -->

                    <!-- begin: date with icon box  -->
                    <div class="row mt-4" style="left: 2.5rem;
                position: relative;">
                        <div class="text-end">
                            <small> &nbsp; </small>
                            <ng-container *ngIf="step?.Start; else expireBlock">
                                <small class="fs-14 " [ngClass]="(stepYourProjects | async).Step.length == 2 ? 'newclass' : ''">{{step.Start | date: 'dd-MM-YYYY'}}<br></small>
                            </ng-container>

                            <ng-template #expireBlock>
                                <small class="fs-14" [ngClass]="(stepYourProjects | async).Step.length !== 2 ? 'color-expired' : 'newclasses'">
                                    {{step.Expire | date: 'dd-MM-YYYY'}}
                                  </small>                            </ng-template>
                            <!-- // se c'è start, fai visualizzare start, altrimenti expiredTime -->
                        </div>
                    </div>
                    <!-- end: date with icon box  -->
                </li>
            </div>
            <!-- end: ngFor step your projects -->
        </div>
    </div>
    <hr class="border-line mt-2">
</div>