import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AdminService } from 'src/app/shared/services/admin.service';
import { customSwal, toast } from 'src/swal';
import { GeneralFormComponent } from '../general-form/general-form.component';

@Component({
  selector: 'app-dynamic-datatables',
  templateUrl: './dynamic-datatables.component.html',
  styleUrls: ['./dynamic-datatables.component.scss']
})
export class DynamicDatatablesComponent implements OnInit {
  clickedIcon: string = '';
  iconHref = 'assets/bootstrap-italia/svg/sprites.svg#it-arrow-up';
  newHref = 'assets/bootstrap-italia/svg/sprites.svg#it-arrow-down';

  _object = Object;

   // used for obtaining the value of the datatables
   @Input() dataSources: any[] = [];

  // used for obtaining the value of the datatables
  @Input() itemSelect: any[] = [];

  // used for create columns datatable
  @Input() columnsDatatable: any;

  // used for obtaining the form data
  @Input() dynamicForms: any;

  // used for button edit - delete
  @Input() actions: any;

  @Output() reloadData: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('generalFormComponent') editForm: GeneralFormComponent | null = null;

  @ViewChild('closeModalButton') closeModal!: any;

  @Input() users: boolean = false;

  @Output() reloadOrder: EventEmitter<any> = new EventEmitter<any>();

  @Input() station: boolean = false;

  userIsDisabled: boolean = false;

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
  }


  getLabel(row: number) {
    switch (row) {
      case 1:
        return 'Consiglio';
      case 2:
        return 'Comitato Speciale';
      case 3:
        return 'Stazione';
      default:
        return '';
    }
  }


  confirmSave() {
    let title = "Sei sicuro di voler modificare?"
    customSwal.fire({
      title: "<p class='fw-600 fs-24'>" + title + "</p>",
      showCancelButton: true,
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then((result: any) => {
      if (result.isConfirmed && this.users) {
        this.adminService.updateUser(this.editForm?.formValue.id, this.editForm?.formValue).subscribe(
          (res) => {
            toast.fire({
              title: "Modificato con successo",
              text: "L'utente è stato modificato correttamente",
              icon: 'success'
            })
            this.reloadData.emit();
            this.closeModal.nativeElement.click();
          });
      } else if (result.isConfirmed && this.station) {
        this.adminService.updateStation(this.editForm?.formValue.id, this.editForm?.formValue).subscribe(
          (res) => {
            toast.fire({
              title: `Stazione modificata`,
              text: `La stazione è stata modificata`,
              icon: 'success'
            })
            this.reloadData.emit();
            this.closeModal.nativeElement.click();
          }

        )
      };
    });
  }

  confirmDelete(element: any, status: boolean) {
    let text_status = this.userIsDisabled ? 'abilitato' : 'disabilitato'
    let title = `Sei sicuro di voler confermare?`
    let changeStatus = {
      "disable": status
    }
    customSwal.fire({
      title: "<p class='fw-600 fs-24'>" + title + "</p>",
      showCancelButton: true,
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Annulla',
      reverseButtons: true
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.adminService.disableUser(element.id, changeStatus).subscribe(
          (result) => {
            toast.fire({
              title: `Utente modificato`,
              text: `L'utente è stato modificato`,
              icon: 'success'
            })
            this.reloadData.emit()
            this.closeModal.nativeElement.click();
          })
      }
    });
  }

  setRow(row: any) {
    this.userIsDisabled = row.is_disabled;
    this.editForm?.form.patchValue(
      {
        email: row.email,
        role: row.role,
        name: row.name,
        first_name: row.first_name,
        code: row.code,
        pec: row.pec,
        id: row.id,
        last_name: row.last_name,
        username: row.username,
        birth_date: row.birth_date,
        birth_place: row.birth_place,
        station_account_id: row.station_account_id,
        committee_roles: row.committe_roles
      }
    )
    this.editForm?.form.addControl('id', new FormControl(row.id))
  }


  removeKey(key: string): boolean {
    // key not visible in datatable
    let allowedKeys = ['role_id', 'created_at', 'updated_at', 'id', 'username', 'station_account_id', 'last_name', 'birth_date', 'birth_place'];
    return !allowedKeys.includes(key) ? true : false;
  }

  changeHref(icon: string) {
    const column = icon.replace(/\s+/g, '');
    const href = document.getElementById(icon)?.querySelector('use')?.getAttribute('href');
    const value = href?.includes('it-arrow-up') ? 'ASC' : 'DESC';
    const element = { column, value };
    this.reloadOrder.emit(element);
    this.iconHref = this.iconHref === 'assets/bootstrap-italia/svg/sprites.svg#it-arrow-up' ?
      this.newHref :
      'assets/bootstrap-italia/svg/sprites.svg#it-arrow-up';
  }

}
