<div class="col-12">
	<label class="col-7 fs-24 fw-700"> {{"GENERAL_INFO" | translate }} 
		<button *ngIf="infoYourProject != null && this.infoYourProject?.TaskId != 'EndEvent'  &&  user?.role === roles.STATION"
			(click)="populateForm(infoYourProject)"
			class="mx-2 btn btn-primary px-3 pt-2" 
			data-bs-toggle="modal" data-bs-target="#editModal"
			data-bs-placement="top" 
			title="Modifica Informazioni">
			<i class="fa-solid  fa-pencil"></i>
		</button>
	</label>
	<label class="col-2 fs-24 fw-700">
		<a [href]="infoYourProject?.ReceiptUrl" *ngIf="infoYourProject != null && this.infoYourProject?.TaskId != 'inoltra'  &&  user?.role === roles.STATION"
			class=" btn btn-primary px-3 pt-2" data-bs-toggle="tooltip" 
			data-bs-placement="top" 
			title="Ricevuta caricamento"><i class="fa-solid fa-download"></i>
		</a>
	</label>
</div>

<!-- begin: all info your projects -->
<ng-container *ngIf="infoYourProject">
	<section class="col-12">
		<div class="row pb-3">
			<label class="col-12 fs-16 fw-bold">{{"PROJECT.NAME_LABEL" | translate}}</label>
			<label class="col-12 fs-16">{{infoYourProject.Name}}</label>
		</div>
		<ng-container *ngIf="user?.role !== roles.STATION">
			<!-- <div class="row pb-3">
				<label class="col-12 fs-16 fw-bold">{{"PROJECT.STATION_LABEL" | translate}}</label>
				<label class="col-12 fs-16">{{infoYourProject.Station}}</label>
			</div> -->
		</ng-container>
		<div class="row pb-3">
			<label class="col-12 fs-16 fw-bold">{{"PROJECT.AMOUNT_LABEL" | translate}}</label>
			<label class="col-12 fs-16">{{infoYourProject.ProjectAmount | currency:
				'EUR':'symbol':undefined:'it-IT'}}</label>
		</div>
		<div class="row pb-3">
			<label class="col-12 fs-16 fw-bold">{{"PROJECT.REFERENT_CONTACTS_PROJECT" | translate}}</label>
			<label class="col-12 fs-16">{{infoYourProject.ProjectReferent}}</label>
		</div>
		<div class="row pb-3">
			<label class="col-12 fs-16 fw-bold">{{"PROJECT.CUP_LABEL" | translate}}</label>
			<label class="col-12 fs-16">{{infoYourProject.Cup}}</label>
		</div>
		<div class="row pb-3">
			<label class="col-12 fs-16 fw-bold">{{"PROJECT.FUNDING_LABEL" | translate}}</label>
			<label class="col-12 fs-16">{{infoYourProject.FundingFormat}}</label>
		</div>
	</section>
</ng-container>
<!-- end: all info your projects -->

<!-- begin: protocollo nomina button -->
<div class="d-flex justify-content-between"
	*ngIf="user?.role == roles.COUNCIL && (projectStatus?.TaskId == 'nomina_commissione' || projectStatus?.TaskId == 'nomina_commissione_conferenza')">
	<span class="fw-bold fs-16 text-center mt-auto mb-auto"> {{"PROTOCOLLO_NOMINA" | translate}}
	</span>


	<ng-container
		*ngIf="((projectStatus?.TaskId == 'nomina_commissione' && checkButton) || (projectStatus?.TaskId == 'nomina_commissione_conferenza' && checkButtonConf))">
		<div>
			<svg class="icon icon-sm icon-success align-self-center suppress-icon" height="18">
				<use href="assets/bootstrap-italia/svg/sprites.svg#it-check-circle">
				</use>
			</svg>
			<span class="fs-16 fw-bold" style="color: green"> Caricato</span>
		</div>

	</ng-container>
	<button class="btn btn-primary btn-sm" (click)="openUpdloadModal('PROTOCOLLO_NOMINA')"
		data-bs-target="#uploadModal2" data-bs-toggle="modal" *ngIf="!checkButton || (projectStatus?.TaskId == 'nomina_commissione_conferenza' && !checkButtonConf)"> Carica </button>

</div>
<!-- end: protocollo nomina button -->

<!-- begin: button station -->
<ng-container *ngIf="projectStatus  && projectStatus.Button != null && user?.role == roles.STATION">
	<button *ngIf="projectStatus.TaskId && !statusProjects.includes(projectStatus.TaskId)" (click)="navigateTo()"
		class="btn btn-primary btn-sm mt-4 col-12 pb-2">
		{{projectStatus.Button.Placeholder | translate}}
	</button>
</ng-container>
<!-- end: button station -->

<!-- begin: button council -->
<ng-container *ngIf="projectStatus && user?.role == roles.COUNCIL && projectStatus.Button != null">
	<button *ngIf="projectStatus.TaskId && approvedAll" (click)="navigateTo()"
		class="btn btn-primary btn-sm mt-4 col-12 pb-2"
		[disabled]="(projectStatus.TaskId == 'nomina_commissione' && !checkButton) || (projectStatus.TaskId == 'nomina_commissione_conferenza' && !checkButtonConf)">
		{{projectStatus.Button.Placeholder | translate}}sda
	</button>
</ng-container>
<!-- end: button council -->

<!-- begin: button Event -->
<ng-container *ngIf="this.projectStatus?.EventButton != null && user?.role != roles.STATION">
	<button  (click)="navigateTo(this.projectStatus?.EventButton)"
					class="btn btn-primary btn-sm mt-4 col-12 pb-2">
		{{projectStatus?.EventButton!.Placeholder | translate}}
	</button>
</ng-container>
<!-- end: button Event -->


<!-- begin: label dibattito pubblico -->


<div class="form-check form-check-inline"
	*ngIf="user?.role == roles.COUNCIL && stepYourProjects.value.StepIndex < 3 && infoYourProject?.EnableDiscussion && !excludeTask.includes(infoYourProject?.TaskId)">
	<input id="checkbox2" type="checkbox" (change)="booleanStatusPublic($event)"
		[checked]="infoYourProject?.PublicDiscussion">
	<label for="checkbox2" class="active fw-bold fs-12" style="font-size: 16px">{{"LABEL_DIBATTITO_PUBBLICO" |
		translate}}</label>
</div>
<!-- end: label dibattito pubblico -->

<!-- begin: file inseriti with modal -->
<ng-container
	*ngIf="user?.role == roles.COUNCIL &&  !excludeTask.includes(infoYourProject?.TaskId) && stepYourProjects.value.StepIndex < 3 && infoYourProject?.PublicDiscussion && !checkDibattito">
	<p class="fw-bold fs-16"> File inseriti:
		<ng-container *ngIf="files && files?.length!=0" class="d-flex">
			<ng-container *ngFor="let file of tempFilesDropped">
				<div class="row d-flex">
					<a href=""> <svg class="icon icon-sm icon-primary align-self-center" height="18">
							<use href="assets/bootstrap-italia/svg/sprites.svg#it-files"></use>
						</svg>{{file.name}} </a>
				</div>
			</ng-container>
		</ng-container>
	</p>
	<button class="btn btn-primary col-12" (click)="openUpdloadModal('DIBATTITO_PUBBLICO')" data-bs-toggle="modal"
		data-bs-target="#uploadModal2" *ngIf="!project">
		{{"UPLOAD_SOSPENSIONE_DIBATTIO" |
		translate}} </button>
</ng-container>
<!-- end: file inseriti with modal -->

<!-- begin: modal upload drag & drop -->
<div class="modal fade" tabindex="-1" role="dialog" id="uploadModal2" aria-labelledby="Drag&Drop Modal"
	data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title h5 no_toc" id="dragDropModalSection">{{"INPUT_FILE" | translate }}</h3>
				<button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Chiudi finestra modale">
					<svg class="icon">
						<use href="assets/bootstrap-italia/svg/sprites.svg#it-close"></use>
					</svg>
				</button>
			</div>
			<div class="modal-body text-center">
				<form class="upload-dragdrop" method="post" action="" enctype="multipart/form-data"
					id="uploadChangeStateTarget" data-bs-upload-dragdrop>

					<!-- begin: modal img left in upload -->
					<div class="upload-dragdrop-image align-self-baseline">
						<img src="/assets/bootstrap-italia/assets/upload-drag-drop-icon.svg" alt="descrizione immagine"
							aria-hidden="true">
					</div>
					<!-- end: modal img left in upload -->

					<div class="upload-dragdrop-text">
						<div class="center">
							<!-- begin: drag & drop file -->
							<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="handleFiles($event)">
								<ng-template ngx-file-drop-content-tmp>
									<h6 id="simTitle">{{"DRAG_THE_FILE_TO_UPLOAD" | translate}}</h6>
								</ng-template>
							</ngx-file-drop>
							<!-- begin: end & drop file -->

							<p id="simText" class="mt-2">{{"OR" | translate}}
								<input type="file" name="upload89" id="upload89"
									(change)="handleFileDevice($event.target)" class="upload-dragdrop-input"
									accept=".pdf">
								<label for="upload89">{{"SELECT_FROM_DEVICE" | translate}}</label>

							</p>

							<div class="upload-table">
								<ng-container *ngFor="let file of files, let i = index">
									<div class="row mt-2">
										<div *ngIf="files && files?.length!=0"
											class="upload-name-style col-6 d-flex ms-2 align-items-center hover-container">
											<svg class="icon icon-sm icon-success align-self-center suppress-icon"
												height="18">
												<use href="assets/bootstrap-italia/svg/sprites.svg#it-check-circle">
												</use>
											</svg>
											<button class="d-none btn bg-transparent btn-sm p-0 btn-remove"
												(click)="removeFile(file, files)">
												<svg class="icon icon-sm icon-danger align-self-center" height="18">
													<use href="assets/bootstrap-italia/svg/sprites.svg#it-close-circle">
													</use>
												</svg>
											</button>
											<span class="text-truncate">{{file.name}}</span>
										</div>
										<div class="file-size col-5">
											<span>{{file.size}}</span>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
					</div>

					<input value="file" type="file" class="d-none" accept="image/png, image/jpeg" />
				</form>
			</div>
			<div class="modal-footer">
				<button class="d-none" data-bs-dismiss="modal" id="closeModalButton" #closeModalButton></button>
				<button class="btn btn-primary btn-sm" type="button" (click)="sendUpload();">
					{{"UPLOAD" | translate}}
				</button>
			</div>
		</div>
	</div>
</div>
<!-- end: modal upload drag & drop -->

<div *ngIf="infoYourProject" class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
	<div class="modal-dialog modal-xl">
	  <div class="modal-content">
		<div class="modal-header">
		  <h5 class="modal-title" id="editModalLabel">Modifica Informazioni del progetto</h5>
		  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		</div>
		<div class="modal-body">
			<h2> {{infoYourProject.Name}}</h2>

		  <form [formGroup]="projectForm">
			<div class="row">
				<div class="mb-3 col-6">
				<label for="projectAmount" class="form-label ">Importo complessivo del progetto</label>
				<input type="number" class="form-control custom-textarea" id="projectAmount" formControlName="ProjectAmount">
				</div>
				<div class="mb-3 col-6 ">
				<label for="projectCUP" class="form-label ">CUP</label>
				<input type="text" class="form-control custom-textarea" id="projectCUP" formControlName="Cup">
				</div>
				<div class="mb-3">
				<label for="projectContact" class="form-label">Riferimenti e contatti del referente di progetto</label>
				<textarea class="form-control custom-textarea" id="projectContact" formControlName="ProjectReferent" rows="3"></textarea>
				</div>
				<div class="mb-3">
				<label for="fundingType" class="form-label">Forma di finanziamento</label>
				<input type="text" class="form-control custom-textarea" id="fundingType" formControlName="FundingFormat">
				</div>
			</div>
		  </form>
		</div>
		<div class="modal-footer">
		  <button type="button" class="btn btn-danger" data-bs-dismiss="modal" >Annulla</button>
		  <button type="button" class="btn btn-primary" (click)="onSubmit()">Salva modifiche</button>
		</div>
	  </div>
	</div>
  </div>
