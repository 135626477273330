import { DOCUMENT, registerLocaleData } from "@angular/common";
import localeIt from '@angular/common/locales/it';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { BehaviorSubject } from 'rxjs';
import { IProject } from 'src/app/shared/models/project';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import { customSwal, toast } from 'src/swal';
import { Role, User } from "../../shared/models/user";
import { AuthService } from "../../shared/services/auth.service";
import { SingleFile } from '../doc-uploader/models/files-array';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { guid } from "@fullcalendar/core/internal";
registerLocaleData(localeIt);


@Component({
  selector: 'app-general-info-your-projects',
  templateUrl: './general-info-your-projects.component.html',
  styleUrls: ['./general-info-your-projects.component.scss'],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' }
  ]
})
export class GeneralInfoYourProjectsComponent implements OnInit {

  @Input() infoYourProject: any = null;
  @Input() projectStatus: IProject | null = null;
  @Input() documentsList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  @Input() documentId: string = '';
  @Input() hiddenButton: boolean = false;
  @Input() stepYourProjects: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  @Output() reloadData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closeModalButton') closeModalButton: any;


  //allowButton = ['calendarizza_evento', 'nomina_commissione', 'approvazione_tecnica', 'inoltro_parere'];
  role: Role | undefined;
  project_id: string = this.activatedRoute.snapshot.params['projectId'];
  fileUpload: boolean = true;
  user: User | null = null;
  roles = Role;
  files = new Array();
  status: boolean = false;
  document: any;
  type: string = '';
  tempFilesDropped = new Array();
  statusProjects: any = ['valutazione_formale', 'calendarizza_evento', 'nomina_commissione']
  arrayApiUpload = ["protocollo_nomina"];
  excludeTask = ["EndEvent", "inoltra", "valutazione_formale", "integrazione_formale"]
  project: boolean = false;
  checkButton: boolean = false;
  integrationsFiles = new Array();
  checkDibattito: boolean = false;
  checkButtonConf: boolean = false;
  unsavedChanges: boolean = false;

  projectForm: FormGroup;
  constructor(@Inject(DOCUMENT) private doc: Document, private router: Router, private authService: AuthService, private projectService: ProjectsService, private activatedRoute: ActivatedRoute,private fb: FormBuilder) {
    this.user = authService.currentUserValue;
    this.document = doc;
    this.integrationsFiles = [{ Id: this.documentId, Files: [...this.files] }];

    this.projectForm = this.fb.group({
      ProjectAmount: [null, Validators.required],
      ProjectReferent: [null, Validators.required],
      Cup: [null, Validators.required],
      FundingFormat: [null, Validators.required],
      guid
    });

    this.projectForm.valueChanges.subscribe(() => {
      this.unsavedChanges = true;
    });
  }


  // used for reset document
  public getDocument(): Document {
    return this.document;
  }

  ngOnInit(): void {
    this.checkButtonState();
    this.checkForNominee();
    this.checkForNomineeConf();    
  }

  populateForm(project: any){
    this.projectForm.patchValue({
      ProjectAmount: project.ProjectAmount,
      ProjectReferent: project.ProjectReferent,
      Cup: project.Cup,
      FundingFormat: project.FundingFormat,
      guid: this.project_id,
    });
  }

  checkButtonState() {
    this.projectService.checkDocuments(this.project_id, 'sospensione_dibattito').subscribe(
      (res: any) => {
        this.checkDibattito = res.Data;
      }
    )
  }

  checkForNominee() {
    this.projectService.checkDocuments(this.project_id, 'nomina_commissione').subscribe(
      (res: any) => {
        this.checkButton = res.Data;
      }
    )
  }

  checkForNomineeConf() {
    this.projectService.checkDocuments(this.project_id, 'nomina_commissione_conferenza').subscribe(
      (res: any) => {
        this.checkButtonConf = res.Data;
      }
    )
  }



  navigateTo( button = this.projectStatus?.Button) {
    let url = (button!.Url)
    url = url?.replace('PROJECT_ID', this.project_id);
    this.router.navigate([url]);
  }

  goTo() {
    this.router.navigateByUrl(this.projectStatus?.Button.Url + '/' + this.project_id);
  }

  

  get approvedAll(): boolean {
    return this.documentsList.value.every((el: any) => {
      if (el.Required) {
        // Gli elementi required devono avere Approved == 1
        return el.Directory.Approved == 2;
      } else {
        // Gli elementi optional devono avere Approved == 0 o 2
        return el.Directory.Approved == 0 || el.Directory.Approved == 2;
      }
    });
  }

  sendStatusPublic() {
    this.projectService.changePublicDiscussion(this.status, this.project_id).subscribe(
      (res) => {
        this.reloadData.emit();
      })
  }

  booleanStatusPublic(event: any) {
    this.status = event.target.checked;
    if (this.status) {
      this.sendStatusPublic();
    } else {
      let title = "Sei sicuro di voler confermare?"
      customSwal.fire({
        title: "<p class='fw-600 fs-24'>" + title + "</p>",
        showCancelButton: true,
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla',
        reverseButtons: true
      }).then((result: any) => {
        if (result.isConfirmed) {
          this.sendStatusPublic();
        }
      })
    }
  }

  handleFiles(files: NgxFileDropEntry[]) {
    for (let file of files) {
      if (file.fileEntry.isFile) {
        const fileEntry = file.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.savefile(file);
        })
      }
    }
  }

  private savefile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const fileHandled: SingleFile = {
        type: file.type,
        name: file.name,
        file: reader.result,
      }
      this.files.push(fileHandled);

      this.integrationsFiles[0].Files = [...this.files];
      this.integrationsFiles[0].Id = this.documentId;
    }
  }

  handleFileDevice(event: any) {
    for (let file of event.files) {
      this.savefile(file);
    }
  }

  openUpdloadModal(type: string) {
    this.type = type;
    this.files = [];
  }

  sendUpload() {
    this.tempFilesDropped = this.files;
    if (this.type === 'PROTOCOLLO_NOMINA') {
      if (this.files.length > 0) {
        this.closeModalButton.nativeElement.click();
        this.projectService.uploadFileIntegrations(this.project_id, { IntegrationsFiles: this.integrationsFiles }).subscribe(
          (res) => {
            if (res) {
              toast.fire({
                title: `Documento caricato`,
                text: `Il documento è stato caricato con successo`,
                icon: 'success'
              })
              this.checkForNominee();
              this.checkForNomineeConf();
              this.reloadData.emit();
              this.fileUpload = false;
            }
          })
      }
    }
    else {
      this.closeModalButton.nativeElement.click();
      this.projectService.documentCode(this.project_id, { DocumentCode: "sospensione_dibattito", Files: this.files }).
        subscribe(res => {
          this.project = true;
        })

    }
  }

  /**
  * remove file from array
  * @param file
  * @param type
  * @param fileArray
  */
  removeFile(file: any, fileArray: any) {
    const index = fileArray.indexOf(file);
    if (index !== -1) {
      fileArray.splice(index, 1);
      this.integrationsFiles[0].Files.splice(index, 1);
    }
  }

  onSubmit() {
    if (this.projectForm.valid) {
      const updatedData = this.projectForm.getRawValue();
      this.unsavedChanges = false;
      
      this.projectService.updateProjectInfo(this.project_id, updatedData).subscribe(
        (res) => {
          this.reloadData.emit();
          toast.fire({
            title: `Progetto aggiornato`,
            text: `Le informazioni del progetto sono state aggiornate con successo`,
            icon: 'success'
          });
        },
        (error) => {
          console.error("Errore durante l'aggiornamento delle informazioni:", error);
          toast.fire({
            title: `Errore`,
            text: `Si è verificato un errore durante l'aggiornamento delle informazioni`,
            icon: 'error'
          });
        }
      );
    }
  }

  openReceiptLink(receiptUrl: string): void {
      window.open(receiptUrl);
  }
}
