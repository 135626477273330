export const environment = {
	production: true,
	debug: true,
	isDevMode: false,
	wso2_apim: {
		url: "https://apim-wso2.darwintech.it:8244",
		ms_project: "t/csllpp.it/ms-projects/v1",
		ms_user: "t/csllpp.it/ms-user/v1",
		ms_calendar: "t/csllpp.it/ms-calendar/v1",
	},
	wso2_identity_server: {
		url: "https://apim-wso2.darwintech.it:9444",
		client_id: "iVIrgxrKEoHRv1Rj1vmD2IvT1Tsa",
		callback_url: "https://dev-cslp.darwintech.it/auth/callback",
		logout_callback_url: "https://dev-cslp.darwintech.it/auth/logout",
		scopes: "openid council committee station",
	},
	nextcloud: {
		url: "https://nextcloud.185.73.249.87.sslip.io/index.php/apps/oidc_login/oidc?login_redirect_url=/index.php/apps/files/?dir=/",
		main_directory: "Gestione_Commessa",
		group_directory:  {
			dir_1: "/EUTALIA_SRL",
			dir_2: "/CSLLPP",
			dir_3: "/GRUPPO_DI_LAVORO"
		}
	}
};
