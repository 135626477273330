import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { NgxFileDropEntry } from 'ngx-file-drop';
import { IDocument } from 'src/app/shared/models/document';
import { IProject } from 'src/app/shared/models/project';
import { Role } from 'src/app/shared/models/user';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProjectsService } from 'src/app/shared/services/projects.service';
import Swal from 'sweetalert2';
import { toast } from "../../app.component";
import { FilesArray, SingleFile } from './models/files-array';

@Component({
  selector: 'app-doc-uploader',
  templateUrl: './doc-uploader.component.html',
  styleUrls: ['./doc-uploader.component.scss']
})
export class DocUploaderComponent implements OnInit {

  /**
   * Contains a list of document types for not required documentation
   * @type {IDocument[]}
   */
  mandatoryDocType: IDocument[] = []

  /**
   * Contains a list of document types for not required documentation
   * @type {IDocument[]}
   */
  optionalDocType: IDocument[] = []

  integrationDoc: any = [];

  /**
  * Contains the document uploaded as integration documentation
  * @type {FilesArray[]}
  */
  integrationsFiles: FilesArray[] = new Array();

  /**
  * Used for create object for uploading the files in to backend
  * @type {SingleFile{}}
  */

  taskExclude = ["inoltra", "valutazione_formale", "integrazione_formale"]


  file = {} as SingleFile;

  directoryUpload: string[] = [
    'inoltra',
    'integrazione_tecnica',
    'integrazione_formale',
    'inoltro_conferenza_servizi',
		'integrazione_formale_conferenza'
  ]

  @Output() outPutData: EventEmitter<any> = new EventEmitter<any>();
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() goBack: EventEmitter<any> = new EventEmitter<any>();

  tempFilesDropped: SingleFile[] = [];
  urlEnd: string = '';
  projectId = this.activatedRoute.snapshot.params['projectId'];
  infoProjects: IProject | undefined;
  documentId: any;
  issueOpinion: boolean = false;
  userRole: Role | undefined;
  requiredError: boolean = false;
  constructor(private router: Router,
    private projectService: ProjectsService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService) {
    this.userRole = this.authService.currentUserValue?.role;

    this.issueOpinion = this.router.url.endsWith('issue/opinion');
  }

  ngOnInit(): void {
    this.getAllInfoProject();
  }

  private getAllInfoProject() {
    // ottiene i documenti
    this.projectService.getProjectUploadDocument(this.projectId).subscribe(
      (res: any) => {
        this.mandatoryDocType = res.Data?.Directory?.Required;
        this.optionalDocType = res.Data?.Directory?.Optional;
        this.integrationDoc = res.Data.Documents;
      }
    )
    this.projectService.getProject(this.projectId).subscribe(
      (res) => {
        this.infoProjects = res.Data;
      });
  }

  private checkExtension(file: File): boolean {
    console.debug("File type:", this.file.type)
    if (this.documentId.Extension) {
      return this.documentId.Extension.includes(file.type)
        && file.type != ""
        && file.type != undefined
    }
    return true;
  }

  private savefile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const fileHandled: SingleFile = {
        type: file.type,
        name: file.name,
        file: reader.result,
        size: this.niceBytes(file.size),
        id: this.documentId.Id
      }
      if (!this.checkExtension(file)) {
        toast.fire({
          title: this.translateService.instant("TOAST.ERROR.FILE_NOT_ALLOWED_TITLE"),
          text: this.translateService.instant("TOAST.ERROR.FILE_NOT_ALLOWED_DESC"),
          icon: 'error'
        });
        throw new Error("NOT_ALLOWED_FILE");
      } else this.tempFilesDropped.push(fileHandled);
    }
  }

  handleFiles(files: NgxFileDropEntry[]) {
    for (let file of files) {
      if (file.fileEntry.isFile) {
        const fileEntry = file.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.savefile(file);
        })
      }
    }
  }

  handleFileDevice(event: any, element: HTMLInputElement) {
    for (let file of event.files) {
      this.savefile(file);
    }
    element.value = "";
  }

  submitModalv2() {
    let item = this.integrationsFiles.find((element: any) => element.Id === this.documentId.Id);
    if (item) {
      item.Files.push(...this.tempFilesDropped);
    } else {
      this.integrationsFiles.push({ Id: this.documentId.Id, Files: [...this.tempFilesDropped] });
    }
    this.tempFilesDropped = [];

  }

  onSubmit() {
    if (this.integrationDoc.length > 0
      && (this.integrationsFiles.filter(el => el.Files.length == 0).length > 0 || this.integrationsFiles.length != this.integrationDoc.length)) {
      toast.fire({
        title: this.translateService.instant("TOAST.ERROR.FILES_REQUIRED_TITLE"),
        text: this.translateService.instant("TOAST.ERROR.FILES_REQUIRED_DESC"),
        icon: 'error'
      })

      this.requiredError = true;

    } else {
      let title = "Sei sicuro di voler procedere?"
      fire.fire({
        title: "<p class='fw-600 fs-24'>" + title + "</p>",
        showCancelButton: true,
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla',
        reverseButtons: true
      }).then((result: any) => {
        if (result.isConfirmed) {
          if (this.userRole === Role.STATION && this.taskExclude.includes(this.infoProjects!.TaskId)) {
            this.urlEnd = `station/projects/new/${this.projectId}/success`
          } else if(this.userRole === Role.STATION && !this.taskExclude.includes(this.infoProjects!.TaskId)) {
            this.urlEnd = `station/projects/new/${this.projectId}/document/success`
          } else if (this.userRole === Role.COUNCIL) {
            this.urlEnd = `council/projects/${this.projectId}`
          }
          if (!this.directoryUpload.includes(this.infoProjects?.TaskId!)) {
            this.projectService.uploadFileIntegrations(this.projectId, { IntegrationsFiles: this.integrationsFiles }).subscribe(
              (result) => {
                this.router.navigate([this.urlEnd]);
              }
            )
          }
           else {
            this.projectService.directorySubmit(this.projectId).subscribe(
              (response) => {
                if (response) {
                  this.router.navigate([this.urlEnd]);
                }
              })
          }
        }
      });
    }

  }


  setDocumentID(item: any) {
    this.documentId = item;
  }


  private niceBytes(x: any) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    };
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }


  /**
   * remove file from array
   * @param file
   * @param type
   * @param fileArray
   */
  removeFile(file: any, fileArray: any) {
    fileArray.splice(fileArray.indexOf(file), 1);
  }


  back() {
    this.outPutData.emit();
    this.goBack.emit();
  }

  backToPage() {
    history.back();
  }

  goToUpload(res: string) {
    window.open(res);
  }

  getFilteredIntegrationsFiles(id: string) {
    return this.integrationsFiles.find((el: FilesArray) => el.Id == id)
  }

}




// swal fire custom class
const fire = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary m-2',
    cancelButton: 'btn btn-outline-primary m-2'
  },
  buttonsStyling: false
});
