import { FilesArray } from "src/app/components/doc-uploader/models/files-array";

export interface DocumentType {
  id: string;
  required: boolean;
  name: string;
  subtitle?: string;
  title?: string;
  extension: string;
  info?: string;
  description?: string;
  format?: string;
}


export enum ResultUpload {
  ACCEPT = 2,
  REJECT = 3,
  WAIT = 1,
  EMPTY = 0
}


export interface DocumentDTO {
    mandatory_files: FilesArray[];
    project_request: any;
    optional_files: FilesArray[];
}


export interface DocumentStatus {
  approved: number;
}