export interface Document {
	id: string;
	approved: boolean,
	draft: boolean,
	name: string,
	state: string,
	note: string
	Directory: any;
	
}


export interface IDocument {
	Id: string,
	Name: string,
	Description: string,
	Required: boolean,
	Integrative?: boolean,
	Url?: string,
	Directory: Directory[]
}

export interface Directory {
	Draft: boolean,
	Id: string
}

export interface ProjectDocuments {
	id: string;
	name: string;
	documents: Document[];
	directory: any;
	optional: [];

}

export interface Trash {
	directory: ProjectDocuments[];
}


export interface ListDocumentsFilter {
	DocumentsStation: {
	  Documents: Document[];
	};
	DocumentsCouncil: {
	  Documents: Document[];
	};
  }
  
  export interface Document {
	Name: string;
	Uri: string;
  }
  